import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  required,
  useTranslate,
  useDataProvider,
  useNotify,
  useRefresh,
  useVersion,
  useLoading,
} from 'react-admin';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Dialog, makeStyles, createStyles, Box } from '@material-ui/core';
import { Stack } from '@mui/material';
import { ReactElementLike } from 'prop-types';
import {
  CustomForm,
  NormalButton,
  DoneButton,
  SelectInput,
  Button,
} from '../../..';
import { SiteBuriedStructureData, SiteData } from '../../../../dataProvider';
import { apiErrorHandler, epsgCode, iconMap, logger } from '../../../../utils';
import { SaveIcon, ReturnIcon, DownloadIcon } from '../../../../assets';
import { CustomDateField } from '../../../atoms';
import { colors } from '../../../../theme';
import { buttonDisabledOpacity } from '../../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    component: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      '& > p': {
        fontSize: '12px',
        paddingRight: '10px',
      },
    },
    header: {
      fontSize: 18,
      margin: 0,
      padding: 24,
    },
    content: {
      fontSize: 18,
      height: 280 - 80, // 80: header
      '& > div > form > div': {
        '& > div > p': {
          fontWeight: 'bold',
          marginBottom: 20,
        },
      },
    },
    mapColor: {
      display: 'flex',
      marginBottom: 32,
      '& > p': {
        fontSize: 12,
        width: 148,
        alignSelf: 'center',
      },
    },
    toolbarButton: {
      position: 'relative',
      bottom: -26,
    },
    footer: {
      marginTop: 10,
    },
    button: {
      backgroundColor: colors.button.cancel,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
    },
  }),
);

const useGetSiteBuriedStructure = () => {
  const dataProvider = useDataProvider();
  return async (record: SiteData): Promise<SiteBuriedStructureData> => {
    const { id: siteId } = record;
    const { data } = await dataProvider.getList<SiteBuriedStructureData>(
      'siteBuriedStructures',
      {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          siteId,
        },
      },
    );
    const [siteBuriedstructureData] = data;
    // デフォルト単位を設定する際は、ダミーIDを設定して返却する
    const results = siteBuriedstructureData || {
      id: undefined,
      siteId,
      epsgCode: '',
    };
    logger().debug('useGetSiteBuriedStructure', results);
    return results;
  };
};

interface FormProps {
  disabled: boolean;
  record?: SiteBuriedStructureData;
  form: any;
}

const SaveButton: React.FC<
  {
    resource: string;
    label: string;
    siteId: string;
    siteUserEmail: string;
  } & Partial<FormProps>
> = ({ disabled, resource, label, siteId, siteUserEmail, record, form }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const loading = useLoading();
  const dataProvider = useDataProvider();

  const handleSave = useCallback(() => {
    if (form.getFieldState('epsgCode').value === '') return;
    const isUpdate = !!record?.id;

    const fn = async () => {
      try {
        if (isUpdate) {
          await dataProvider.update(resource, {
            id: record.id,
            data: {
              ...record,
              epsgCode: form.getFieldState('epsgCode').value,
              siteUserEmail,
            },
            previousData: record,
          });
        } else {
          await dataProvider.create(resource, {
            data: {
              siteId,
              epsgCode: form.getFieldState('epsgCode').value,
              siteUserEmail,
            },
          });
        }
        notify('admin.message.success', 'info');
        refresh();
      } catch (error) {
        notify(apiErrorHandler(error), 'warning');
      }
    };
    fn();
  }, [
    siteId,
    resource,
    siteUserEmail,
    record,
    form,
    dataProvider,
    notify,
    refresh,
  ]);

  const handleClick = useCallback(() => {
    if (!record) return;
    handleSave();
  }, [record, handleSave]);

  return (
    <>
      <DoneButton
        label={label}
        disabled={disabled || loading}
        onClick={handleClick}
      >
        <SaveIcon />
      </DoneButton>
    </>
  );
};

const SiteBuriedStructureTaskIcon: React.FC<{
  resource: string;
  source: string;
  record?: SiteBuriedStructureData;
}> = ({ resource, source, record }) => {
  if (!record || !record.blobFileStatus) return <></>;

  const value: string = record.blobFileStatus.toString().toLowerCase();
  const Icon = iconMap[resource][source][value];
  if (!Icon) return null;
  return <Icon />;
};

const SiteBuriedStructureDownload: React.FC<{
  record?: SiteBuriedStructureData;
  className: string;
}> = ({ record, className }) => {
  if (!record || !record.id) return null;
  return (
    <Button
      className={className}
      href={record.url}
      onClick={e => e.stopPropagation()}
      download
      disabled={record.blobFileStatus !== 'Created'}
    >
      <DownloadIcon />
    </Button>
  );
};

// CustomForm の props を伝達するコンポーネント
// また、Button の位置調整は 本コンポーネントで行う
const ToolbarButton: React.FC<{ children?: ReactElementLike }> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  const button = React.isValidElement(children)
    ? React.cloneElement(children, { ...props })
    : null;
  return <div className={classes.toolbarButton}>{button}</div>;
};

const SiteBuriedStructureEdit: React.FC<{
  open: boolean;
  siteUserEmail: string | undefined;
  record?: SiteData;
  onClose: () => void;
}> = ({ open, siteUserEmail, onClose, record }) => {
  if (!record) throw new Error('Invalid record');
  if (!siteUserEmail) throw new Error('Invalid siteUserEmail');
  const resource = 'siteBuriedStructures';
  const translate = useTranslate();
  const classes = useStyles();
  const getSiteBuriedStructure = useGetSiteBuriedStructure();
  const currentVersion = useVersion();

  const ref = useRef<HTMLDivElement>(null);

  const [fetchedUnit, setFetchedUnit] = useState(false);
  const [siteBuriedStructureRecord, setSiteBuriedStructureRecord] = useState<
    SiteBuriedStructureData | undefined
  >(undefined);
  const [preVersion, setPreVersion] = useState(0);
  const [disabledState, setDisabledState] = useState(false);

  const { id: siteId } = record;

  useEffect(() => {
    if (preVersion !== currentVersion) {
      setPreVersion(currentVersion);
      setSiteBuriedStructureRecord(undefined); // version変更時に明示的にクリアすることでダイアログ表示時にリストをリロードする
    }
  }, [preVersion, currentVersion]);

  useEffect(() => {
    if (siteBuriedStructureRecord === undefined && !fetchedUnit) {
      setFetchedUnit(true);
      getSiteBuriedStructure(record).then((result: SiteBuriedStructureData) => {
        setSiteBuriedStructureRecord(result);
      });
    }
  }, [record, getSiteBuriedStructure, siteBuriedStructureRecord, fetchedUnit]);

  // キャンセルボタン・ダイアログ枠外をクリックなど
  const resetColorInfo = useCallback(() => {
    onClose();
  }, [onClose]);

  // Select内の表示
  const selectOptionTextRenderer = (choice: { id: any; name: any }) =>
    `${choice.id} ${choice.name}`;

  const onChangeEvent = (elem: any) => {
    setDisabledState(elem.target.value === siteBuriedStructureRecord?.epsgCode);
  };

  return (
    <Dialog open={open} onClose={resetColorInfo} maxWidth={'lg'}>
      <MuiDialogTitle>
        {translate('admin.dialog.siteBuriedStructureEdit.title')}
      </MuiDialogTitle>
      <MuiDialogContent className={classes.content}>
        <div ref={ref}>
          {open ? (
            <CustomForm
              resource={resource}
              record={siteBuriedStructureRecord}
              bottomToolbar={true}
              deleteButton={false}
              cancelButton={
                <ToolbarButton>
                  <NormalButton
                    onClick={resetColorInfo}
                    label="admin.actions.cancel"
                  >
                    <ReturnIcon />
                  </NormalButton>
                </ToolbarButton>
              }
              saveButton={
                <ToolbarButton>
                  <SaveButton
                    disabled={disabledState}
                    resource={resource}
                    label="admin.actions.save"
                    siteId={siteId}
                    siteUserEmail={siteUserEmail}
                    record={siteBuriedStructureRecord}
                  />
                </ToolbarButton>
              }
            >
              <Stack direction="row">
                <SelectInput
                  resource={resource}
                  source="epsgCode"
                  choices={epsgCode}
                  validate={[required()]}
                  resettable={false}
                  optionText={selectOptionTextRenderer}
                  onChange={onChangeEvent}
                />
                <Box className={classes.component}>
                  <SiteBuriedStructureTaskIcon
                    resource={resource}
                    source="status"
                    record={siteBuriedStructureRecord}
                  />
                </Box>
                <Box className={classes.component}>
                  <CustomDateField
                    resource={resource}
                    record={siteBuriedStructureRecord}
                    source="fileTime"
                    showTime
                  />
                </Box>
                <SiteBuriedStructureDownload
                  record={siteBuriedStructureRecord}
                  className={classes.button}
                />
              </Stack>
            </CustomForm>
          ) : null}
        </div>
      </MuiDialogContent>
    </Dialog>
  );
};

SiteBuriedStructureEdit.displayName = 'SiteBuriedStructureEdit';
export default SiteBuriedStructureEdit;
